body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  display: none;
}



.fullPageTable .MuiTable-root .MuiTableCell-root {
  border-right: 3px solid #fafafa;
  white-space: pre-wrap;
  overflow-y: scroll;
  overflow-x: hidden;
}

.fullPageTable .MuiTable-root .MuiTableCell-root:last-child {
  border-right: none;

}


.fullPageTable .multipleObjectsCell {
  display: block;
  max-height: 110px;
}

.cardMode  .multipleObjectsCell {
  display: block;
  max-height: 20px;
}

.modalEditListItem{
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
}

details > summary {
  cursor: pointer;
  font-weight: bold;
}

details > summary::-webkit-details-marker {
  display: none;
}

details[open] > summary {
  margin-bottom: 10px;
}

details summary::-webkit-details-marker {
  display:none;
}
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}


.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.animated-text {
  opacity: 0;
  transform: translateY(-20px);
  animation: slideFadeIn 1s ease forwards;
}

@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


iframe#webpack-dev-server-client-overlay {
  display: none;
}